import React from "react";
import styled from "styled-components";

import { BlocksContent, GoogleMap, Image, Link } from "@global";
import { Maybe, SanityHowToFindUs } from "@graphql-types";
import { mediaQuery } from "@util/constants";
import { useStore } from "@state/store";
import { Container, DesktopContainer, PageWidth, Section, TabletContainer } from "@util/standard";
import { MapMarker } from "@components/mapMarker";

interface Props {
  data: Maybe<SanityHowToFindUs> | undefined;
  addressUrl: string;
}

const ariaLabel = "How to find us";

export default function HowToFindUs({ data, addressUrl }: Props) {
  return (
    <Section aria-labelledby={ariaLabel}>
      <PageWidth>
        <Title>{data?.title ?? ariaLabel}</Title>
      </PageWidth>

      <TabletContainer height="max-content" margin="20px 0">
        {data?.mapImage?.asset ? (
          <Image data={data?.mapImage} width="100%" />
        ) : (
          <Container height="40vh" width="100%">
            <Map />
          </Container>
        )}
      </TabletContainer>

      <StyledPageWidth>
        <Container isTabletColumn height="100vh">
          <DesktopContainer width="74%" height="max-content" margin="0 2% 0 0">
            {data?.mapImage?.asset ? (
              <Image data={data?.mapImage} width="100%" />
            ) : (
              <Container height="60vh" width="100%">
                <Map />
              </Container>
            )}
          </DesktopContainer>

          <Container flexDirection="column" width="23%" tabletWidth="100%" rowGap="55px">
            <BlocksContent data={data?.text} />
            <Link linkText="Get directions" externalLink={addressUrl} className="button-base" />
          </Container>
        </Container>
      </StyledPageWidth>
    </Section>
  );
}

const Map = () => {
  const { village } = useStore();

  return (
    <GoogleMap mapCenter={{ lat: village.lat, lng: village.lng }}>
      <MapMarker lat={village.lat} lng={village.lng} />
    </GoogleMap>
  );
};

const Title = styled.h2`
  margin-bottom: 40px;
  ${mediaQuery.tabletDown} {
    margin-bottom: 20px;
  }
`;

const StyledPageWidth = styled(PageWidth)`
  max-width: calc(100% - 90px);
`;
