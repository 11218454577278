import React from "react";
import { graphql, PageProps } from "gatsby";

import { Query } from "@graphql-types";
import { ErrorMsg, Header, SEO } from "@shared";
import { usePageMeta } from "@util/logicHooks";
import ContactHeading from "@components/village/contactHeading";
import HowToFindUs from "@components/village/howToFindUs";
import { formatAddress } from "@util/helper";
import { VillagePageContext } from "@state/types";

interface Props extends PageProps {
  data: Query;
  pageContext: VillagePageContext;
}

export default function VillageContactTemplate({
  data: { sanityVillage, sanityHeader },
  pageContext,
}: Props) {
  if (sanityVillage == null)
    return <ErrorMsg data={sanityVillage} msg="Error fetching data for page" />;

  const {
    slug,
    villageColour,
    _type,
    overviewSeo,
    howToFindUs,
    secondaryVillageColour,
    contactSeo,
    contactForm,
  } = sanityVillage;
  const { url } = formatAddress(sanityVillage);
  usePageMeta(villageColour, _type, pageContext);

  return (
    <div>
      <SEO seoData={contactSeo ?? overviewSeo} slug={slug?.current as string} />
      <Header data={sanityHeader} village={sanityVillage} />
      <ContactHeading data={sanityVillage} secondaryVillageColour={secondaryVillageColour} />
      <HowToFindUs data={howToFindUs} addressUrl={url} />
    </div>
  );
}

export const query = graphql`
  query villageContactQuery($slug: String) {
    sanityHeader {
      ...sanityHeader
    }
    sanityVillage(slug: { current: { eq: $slug } }) {
      ...sanityVillageContact
    }
  }
`;
